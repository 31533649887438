import ReactGallery from '../components/ReactGallery'

function Galerie() {
  return (
    <div className='container mx-auto  border-gray-300'>
      <ReactGallery />
    </div>
  )
}

export default Galerie
