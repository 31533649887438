import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { useState } from 'react'
import { IoMdClose } from 'react-icons/io'

import image1 from '../assets/galerie/nuferi.jpg'
import image2 from '../assets/galerie/asd.jpg'
import image3 from '../assets/galerie/pelicani.jpg'
import image4 from '../assets/galerie/backyard.jpg'
import image5 from '../assets/galerie/500_3957_resize.jpg'
import image6 from '../assets/galerie/_DSC9484-2_resize.jpg'
import image7 from '../assets/galerie/500_4378_resize.jpg'
import image8 from '../assets/galerie/barcamare.jpg'
import image9 from '../assets/galerie/saramura.jpg'
import image10 from '../assets/galerie/apus2.jpg'
import image11 from '../assets/galerie/500_4044_resize.jpg'
import image12 from '../assets/galerie/sturg.jpg'
import image13 from '../assets/galerie/spatecurte.jpg'
import image14 from '../assets/galerie/scaune.jpg'
import image15 from '../assets/galerie/storceag.jpg'
import image16 from '../assets/galerie/aperitive.jpg'
import image17 from '../assets/galerie/somn.jpg'
import image18 from '../assets/galerie/pesteprajit.jpg'
import image19 from '../assets/galerie/caraorman.jpg'
import image20 from '../assets/galerie/paste.jpg'
import image21 from '../assets/galerie/oameni.jpg'
import image22 from '../assets/galerie/spatecurte2.jpg'
import image23 from '../assets/galerie/pasca.jpg'
import image24 from '../assets/galerie/backyardwide.jpg'
import image25 from '../assets/galerie/_DSC9322_resize.jpg'
import image26 from '../assets/galerie/_DSC9330_resize.jpg'
import image27 from '../assets/galerie/20210827_211506.jpg'
import image28 from '../assets/galerie/500_3403_resize.jpg'
import image29 from '../assets/galerie/500_3522_resize.jpg'
import image30 from '../assets/galerie/500_3560_resize.jpg'
import image31 from '../assets/galerie/500_3577_resize.jpg'
import image32 from '../assets/galerie/500_3830_resize.jpg'
import image33 from '../assets/galerie/500_3852_resize.jpg'
import image34 from '../assets/galerie/500_3875_resize.jpg'
import image35 from '../assets/galerie/500_4016_resize.jpg'
import image36 from '../assets/galerie/20210731_155418.jpg'
import image37 from '../assets/galerie/prajiura.jpg'
import image38 from '../assets/galerie/500_4374_resize.jpg'
import image39 from '../assets/galerie/rasarit2.jpg'
import image40 from '../assets/galerie/award.png'
import image41 from '../assets/galerie/camera1.jpg'
import image42 from '../assets/galerie/camera5.jpg'
import image43 from '../assets/galerie/camera8.jpg'

const images = [
  { id: 1, src: image1 },
  { id: 2, src: image2 },
  { id: 3, src: image3 },
  { id: 4, src: image4 },
  { id: 5, src: image5 },
  { id: 6, src: image6 },
  { id: 7, src: image7 },
  { id: 8, src: image8 },
  { id: 9, src: image9 },
  { id: 10, src: image10 },
  { id: 11, src: image11 },
  { id: 12, src: image12 },
  { id: 13, src: image13 },
  { id: 14, src: image14 },
  { id: 15, src: image15 },
  { id: 16, src: image16 },
  { id: 17, src: image17 },
  { id: 18, src: image18 },
  { id: 19, src: image19 },
  { id: 20, src: image20 },
  { id: 21, src: image21 },
  { id: 22, src: image22 },
  { id: 23, src: image23 },
  { id: 24, src: image24 },
  { id: 25, src: image25 },
  { id: 26, src: image26 },
  { id: 27, src: image27 },
  { id: 28, src: image28 },
  { id: 29, src: image29 },
  { id: 30, src: image30 },
  { id: 31, src: image31 },
  { id: 32, src: image32 },
  { id: 33, src: image33 },
  { id: 34, src: image34 },
  { id: 35, src: image35 },
  { id: 36, src: image36 },
  { id: 37, src: image37 },
  { id: 38, src: image38 },
  { id: 39, src: image39 },
  { id: 40, src: image40 },
  { id: 41, src: image41 },
  { id: 42, src: image42 },
  { id: 43, src: image43 },
]

function ReactGallery() {
  const [data] = useState({ img: '', i: 0 })

  return (
    <div>
      {data.img && (
        <div className='hidden md:fixed top-0 left-0 md:flex justify-center items-center  w-full h-screen bg-gray-700 bg-opacity-75  fixed overflow-hidden'>
          <div className='relative w-auto scale-125 '>
            <img src={data.img} alt='' />
            <IoMdClose
              className='absolute top-2 right-2'
              style={{ cursor: 'pointer', color: 'black', fontSize: '28px' }}
              onClick={() => {}}
            />
          </div>
        </div>
      )}

      <ResponsiveMasonry columnsCountBreakPoints={{ 480: 1, 768: 2, 1080: 4 }}>
        <Masonry>
          {images.map((image) => (
            <img
              className='border-4   border-gray-300 hover:scale-110 transition duration-300 ease-in-out'
              key={image.id}
              alt=''
              src={image.src}
              style={{ width: '100%', display: 'block' }}
              // onClick={() => viewImage(image.src, image.id)}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  )
}

export default ReactGallery
