import React from 'react'

function Tarife() {
  return (
    <div className='container mx-auto my-8  max-w-4xl p-3 rounded-lg bg-gray-300 md:my-4'>
      {/* container of the packages */}
      <div className='flex flex-col max-w-6xl items-center justify-between'>
        {/* titlu  */}
        <h3 className='text-4xl text-gray-600 font-bold text-center '>
          Tarifele Pachetelor 2023
        </h3>
        {/* container of the I package */}
        <div className='flex flex-col items-center justify-between text-center border-myBlue border-1 text-gray-500 my-4'>
          <h3 className='text-xl font-bold'>Pachet 2 nopți - 570 lei / pers</h3>
          <h2 className='text-xl text-center'>Tariful include:</h2>

          <p className=''>Două nopți de cazare cu demipensiune </p>
          <p>(Mic dejun pescăresc + prânz bazat pe două feluri din pește)</p>
          <p className=''>
            O excursie de <strong className='font-bold'> minimum 3 ore </strong>{' '}
            în Rezervația Deltei Dunării
          </p>
        </div>

        {/* container of the II package */}
        <div className='flex flex-col items-center justify-between text-center border-myBlue border-1 text-gray-500 mx-2'>
          <h3 className='text-xl font-bold'>Pachet 3 nopti - 950 lei / pers</h3>
          <h2 className='text-xl text-center'>Tariful include:</h2>
          <p className=''>Trei nopți de cazare cu demipensiune</p>
          <p>(Mic dejun pescăresc + prânz bazat pe două feluri din pește)</p>
          <p>
            O excursie de <strong className='font-bold'> minimum 3 ore </strong>{' '}
            in Rezervatia Deltei Dunarii
          </p>

          <p>O excursie de o zi la gura de vărsare a Dunării</p>
          <br />
          <br />
          <p>
            *Rezervările se fac achiziționând{' '}
            <strong className='font-bold'> întregul pachet </strong> menționat
            mai sus pentru a vă oferi posibilitatea de a vă bucura de
            <strong className='font-bold'> experiențele inedite </strong> pe
            care le oferă Delta Dunării în cadrul pensiunii noastre (
            <strong className='font-bold'>
              {' '}
              plimbarea cu barca în natură și mâncarea tradițională{' '}
            </strong>
            )
          </p>
          <p>
            *La <strong className='font-bold'> cererea dumneavoastră </strong>,
            la pachetele de bază se pot adăuga și alte{' '}
            <strong className='font-bold'> servicii suplimentare </strong>
            (ex: cină, posibilitatea micului dejun international, diferite
            excursii).
          </p>
        </div>

        <div className='flex flex-col items-center justify-between text-center border-myBlue border-1 text-gray-500 mx-2'></div>
      </div>
    </div>
  )
}

export default Tarife
