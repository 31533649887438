import React from 'react'
import deltadunarii from '../assets/galerie/deltadunarii.jpg'
import sulina from '../assets/galerie/sulina.jpeg'
import letea from '../assets/galerie/letea.jpg'
import caraorman from '../assets/galerie/caraorman.jpeg'
import sfgheorghe from '../assets/galerie/sfgheorghe.jpeg'
// import YoutubeEmbed from '../components/YoutubeEmbed'

function Excursii() {
  const handleClick = () => {
    window.open('tel:+40748596553')
  }
  return (
    <div className='container mx-auto max-w-6xl px-2 md:px-10'>
      <div>
        <h3 className='mb-16 text-4xl text-center  font-bold text-white md:text-5xl'>
          Excursii
        </h3>
      </div>
      <div className='container mx-auto my-8  max-w-6xl p-4 rounded-lg shadow-lg bg-gray-300 border-double border-4 border-gray-400'>
        <p>
          Ambarcațiunile noastre sunt în{' '}
          <strong className='font-bold'>
            {' '}
            condiție excelentă de funcționare{' '}
          </strong>{' '}
          din punct de vedere al{' '}
          <strong className='font-bold'> siguranței </strong>, dotate cu{' '}
          <strong className='font-bold'> vestă de salvare </strong> pentru
          fiecare persoană aflată la bord. În ceea ce privește{' '}
          <strong className='font-bold'> confortul </strong>,
          <strong className='font-bold'> canapelele </strong> din piele
          sintetică împiedică șocul valurilor să vă deranjeze, iar{' '}
          <strong className='font-bold'> umbrarul </strong> blochează razele
          soarelui pentru a vă putea bucura de plimbare fără a fi sufocați de
          căldura verii. Un alt aspect cu care ne mândrim este{' '}
          <strong className='font-bold'> curățenia exemplară </strong> a
          ambarcațiunilor și faptul că au fost{' '}
          <strong className='font-bold'> recent achiziționate </strong>.
          Conducătorii sunt
          <strong className='font-bold'> experimentați </strong> și cunosc
          foarte bine atât labirintul canalelor, cât și toate{' '}
          <strong className='font-bold'> informațiile </strong> despre ceea ce
          urmează să vizitați.
        </p>
        <br />
        <p>
          In cadrul{' '}
          <strong className='font-bold'> Casei Matei Mahmudia </strong> din
          Delta Dunarii, oferim o varietate de{' '}
          <strong className='font-bold'> excursii cu barca </strong> pentru a vă
          bucura de peisajul unic al Deltei si de a explora frumusetile naturale
          ale acestei zone. Iata cateva dintre{' '}
          <strong className='font-bold'> tipurile de excursii </strong> cu barca
          pe care le oferim impreuna cu cateva{' '}
          <strong className='font-bold'> videoclipuri de prezentare </strong> a
          ambarcatiunilor:
        </p>
      </div>
      <div className='container mx-auto max-w-6xl px-6 py-12 '>
        {/* text cu descriere universala + titulu vedem */}
        {/* container of all cards */}
        <div className='flex flex-col justify-center items-center space-y-16'>
          {/* cards container 1 */}
          <div className='flex flex-col items-center justify-between space-y-16 md:flex-row md:space-x-12 md:space-y-0'>
            {/* card 1 */}
            <div
              className='max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden 
              border-4 border-white'
            >
              <img className='w-full' src={deltadunarii} alt='random' />
              <div className=' flex flex-col items-center px-6 py-4'>
                <h3 className='font-bold text-xl text-center mb-2'>
                  Excursie Delta Dunarii
                </h3>
                <p className='text-gray-700 text-base mb-2'>
                  Pret: 100 lei/persoana
                </p>
                <p className='text-gray-700 text-base text-center mb-4'>
                  Numarul minim de participanti este de 5.
                </p>
                <p className='text-gray-700 text-base text-center mb-4'>
                  Durata: <strong className='font-bold'> minimum 3 ore </strong>
                </p>

                <div className='flex flex-row '>
                  <button
                    onClick={handleClick}
                    className='bg-myBlue  text-white font-bold py-2 px-4 rounded-full hover:opacity-75'
                  >
                    Rezerva
                  </button>
                  {/* {isOpen && <RezervaModal onClose={toggleModal} />} */}
                </div>
              </div>
            </div>
            {/* card 2 */}
            <div className='max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden border-4 border-white'>
              <img className='w-full' src={sulina} alt='random' />
              <div className=' flex flex-col items-center px-6 py-4'>
                <h3 className='font-bold text-xl mb-2'>
                  Excursie Plaja Sulina
                </h3>
                <p className='text-gray-700 text-base mb-2'>
                  Pret: 150 lei/persoana
                </p>
                <p className='text-gray-700 text-center text-base mb-4'>
                  Numarul minim de participanti este de 5.
                </p>
                <p className='text-gray-700 text-base text-center mb-4'>
                  Durata: <strong className='font-bold'> o zi </strong>
                </p>
                <div className='flex flex-row '>
                  <button
                    onClick={handleClick}
                    className='bg-myBlue  text-white font-bold py-2 px-4 rounded-full hover:opacity-75'
                  >
                    Rezerva
                  </button>
                  {/* {isOpen && <RezervaModal onClose={toggleModal} />} */}
                </div>
              </div>
            </div>
          </div>

          {/* cards container 2 */}
          <div className='flex flex-col items-center justify-between space-y-16 md:flex-row md:space-x-12 md:space-y-0'>
            {/* card 1 */}
            <div className='max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden border-4 border-white'>
              <img className='w-full' src={letea} alt='random' />
              <div className=' flex flex-col items-center px-6 py-4'>
                <h3 className='font-bold text-xl mb-2'>
                  Excursie Padurea Letea
                </h3>
                <p className='text-gray-700 text-base mb-2'>
                  Pret: 150 lei/persoana
                </p>
                <p className='text-gray-700 text-center text-base mb-4'>
                  Numarul minim de participanti este de 5.
                </p>
                <p className='text-gray-700 text-base text-center mb-4'>
                  Durata: <strong className='font-bold'> o zi </strong>
                </p>
                <div className='flex flex-row '>
                  <button
                    onClick={handleClick}
                    className='bg-myBlue  text-white font-bold py-2 px-4 rounded-full hover:opacity-75'
                  >
                    Rezerva
                  </button>
                </div>
              </div>
            </div>
            {/* card 2 */}
            <div className='max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden border-4 border-white'>
              <img className='w-full' src={sfgheorghe} alt='random' />
              <div className=' flex flex-col items-center px-6 py-4'>
                <h3 className='font-bold text-xl mb-2'>
                  Excursie Plaja Sf. Gheorghe
                </h3>
                <p className='text-gray-700 text-base mb-2'>
                  Pret: 150 lei/persoana
                </p>
                <p className='text-gray-700 text-center text-base mb-4'>
                  Numarul minim de participanti este de 5.
                </p>
                <p className='text-gray-700 text-base text-center mb-4'>
                  Durata: <strong className='font-bold'> o zi </strong>
                </p>
                <div className='flex flex-row '>
                  <button
                    onClick={handleClick}
                    className='bg-myBlue  text-white font-bold py-2 px-4 rounded-full hover:opacity-75'
                  >
                    Rezerva
                  </button>
                  {/* {isOpen && <RezervaModal onClose={toggleModal} />} */}
                </div>
              </div>
            </div>
          </div>

          {/* cards container 3 */}
          <div className='flex flex-col items-center justify-between md:flex-row'>
            {/* card 1 */}
            <div className='max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden border-4 border-white'>
              <img className='w-full' src={caraorman} alt='random' />
              <div className=' flex flex-col items-center px-6 py-4'>
                <h3 className='font-bold text-xl mb-2'>
                  Excursie Padurea Caraorman
                </h3>
                <p className='text-gray-700 text-base mb-2'>
                  Pret: 150 lei/persoana
                </p>
                <p className='text-gray-700 text-center text-base mb-4'>
                  Numarul minim de participanti este de 5.
                </p>
                <p className='text-gray-700 text-base text-center mb-4'>
                  Durata: <strong className='font-bold'> o zi </strong>
                </p>
                <div className='flex flex-row '>
                  <button
                    onClick={handleClick}
                    className='bg-myBlue  text-white font-bold py-2 px-4 rounded-full hover:opacity-75'
                  >
                    Rezerva
                  </button>
                  {/* {isOpen && <RezervaModal onClose={toggleModal} />} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-center items-center m-4 space-y-12  md:flex-row md:space-y-0 md:space-x-12 '>
        {/* <YoutubeEmbed videoId='cx8Gs8zYXhY' />
        <YoutubeEmbed videoId='cdmoWSYBDkg' /> */}
      </div>
    </div>
  )
}

export default Excursii
