import React from 'react'

import { FaWifi } from 'react-icons/fa'
import { FaUtensils } from 'react-icons/fa'
import { FaFan } from 'react-icons/fa'
import { FaParking } from 'react-icons/fa'
import { TbSpeedboat } from 'react-icons/tb'
import { GiBoatFishing } from 'react-icons/gi'
import { GiTowel } from 'react-icons/gi'
import { MdCleaningServices } from 'react-icons/md'
import { FaTv } from 'react-icons/fa'

function Facilitati() {
  return (
    <>
      <section>
        <div className='max-w-6xl mx-auto text-center px-2 my-8 md:px-10'>
          <div>
            <h3 className='mb-16 text-4xl  font-bold text-white md:text-5xl'>
              Facilități
            </h3>
          </div>

          <div className='flex flex-col justify-between items-center   text-gray-400'>
            <div className=' mx-auto my-8  max-w-6xl p-4 rounded-lg shadow-lg bg-gray-300 border-double border-4 border-gray-400'>
              <p className='text-black text-left'>
                <strong className='font-bold'>Casa Matei Mahmudia</strong> este
                situată pe{' '}
                <strong className='font-bold'> brațul Sf. Gheorghe </strong> al
                Dunării, la <strong className='font-bold'>25 de km </strong> de
                orașul Tulcea, ajungându-se cu mașina până în curtea pensiunii.
                Este o locație de cazare care dispune de{' '}
                <strong className='font-bold'>7 camere duble </strong>cu pat
                matrimonial și{' '}
                <strong className='font-bold'>
                  {' '}
                  o cameră triplă cu balcon
                </strong>
                , toate camerele fiind dotate cu{' '}
                <strong className='font-bold'> băi proprii </strong>. În plus,
                aceasta are un restaurant unde puteți savura
                <strong className='font-bold'>
                  {' '}
                  preparate tradiționale pescărești
                </strong>{' '}
                delicioase și rafinate.{' '}
                <strong className='font-bold'>
                  Maioritatea produselor{' '}
                </strong>{' '}
                servite în bucătăria noastră provin din{' '}
                <strong className='font-bold'>surse BIO </strong>. În această
                locație totul este înverzit și frumos, iar grădina cu livada cu
                pomi fructiferi și bolta de vie cu struguri de masă sunt
                atracțiile remarcate de oaspeții noștri. Cafeaua servită este
                foarte bună, având de asemenea posibilitatea de a cumpăra
                băuturi răcoritoare și alcoolice. Fiecare cameră este dotată cu
                un minifrigider, aer condiționat, prosoape curate și feon.
                Pentru pasionații de{' '}
                <strong className='font-bold'>pescuit </strong> sau pentru cei
                care doresc să admire apusul, locația oferă un{' '}
                <strong className='font-bold'>
                  ponton privat dotat cu umbrar{' '}
                </strong>
                . De asemenea, este posibilă organizarea unei{' '}
                <strong className='font-bold'>cine romantice </strong> la
                pontonul privat. Personalul este mereu la{' '}
                <strong className='font-bold'>
                  {' '}
                  dispoziția dumneavoastră{' '}
                </strong>{' '}
                pentru orice aveți nevoie.{' '}
                <strong className='font-bold'>Check-in-ul </strong> se face
                începând cu <strong className='font-bold'> ora 14:00 </strong>,
                însă dacă ajungeți mai devreme puteți aștepta să vă cazați la o
                cafea în curtea pensiunii. Check-out-ul se face la{' '}
                <strong className='font-bold'> ora 11:00</strong>.
              </p>
            </div>

            {/* exran mare */}
            <div className='hidden  sm:flex flex-col space-y-10'>
              {/* first container for 4 icons */}
              <div className='flex flex-row items-center justify-between space-x-24 '>
                <div className='flex flex-col  items-center space-y-5 '>
                  <FaWifi
                    style={{
                      color: 'white',
                      fontSize: '60px',
                    }}
                  />

                  <p className='max-w-md text-grayishBlue'>WiFi Gratuit</p>
                </div>

                <div className='flex flex-col  items-center space-y-5'>
                  <FaUtensils
                    style={{
                      color: 'white',
                      fontSize: '60px',
                    }}
                  />

                  <p className='max-w-md text-grayishBlue'>
                    Restaurant si Terasa
                  </p>
                </div>

                <div className='flex flex-col  items-center space-y-5'>
                  <FaFan
                    style={{
                      color: 'white',
                      fontSize: '60px',
                    }}
                  />
                  <p className='max-w-md text-grayishBlue'>Aer Conditionat</p>
                </div>
              </div>
              {/* second container for 4 icons */}
              <div className='flex flex-row items-center justify-between space-x-24 '>
                <div
                  className='flex flex-col  items-center space-y-5
            '
                >
                  <TbSpeedboat
                    style={{
                      color: 'white',
                      fontSize: '60px',
                    }}
                  />

                  <p className='max-w-md text-grayishBlue'>Plimbari cu barca</p>
                </div>
                <div className='flex flex-col  items-center space-y-5'>
                  <GiBoatFishing
                    style={{
                      color: 'white',
                      fontSize: '60px',
                    }}
                  />

                  <p className='max-w-md text-grayishBlue'>Pescuit</p>
                </div>
                <div className='flex flex-col  items-center space-y-5'>
                  <MdCleaningServices
                    style={{
                      color: 'white',
                      fontSize: '60px',
                    }}
                  />

                  <p className='max-w-md text-grayishBlue'>
                    Curatenie exemplara
                  </p>
                </div>
              </div>

              {/* third shit */}
              <div className='flex flex-row items-center justify-between space-x-24 '>
                <div className='flex flex-col  items-center space-y-5'>
                  <FaTv
                    style={{
                      color: 'white',
                      fontSize: '60px',
                    }}
                  />

                  <p className='max-w-md text-grayishBlue'>Smart Tv</p>
                </div>

                <div className='flex flex-col  items-center space-y-5'>
                  <GiTowel
                    style={{
                      color: 'white',
                      fontSize: '60px',
                    }}
                  />
                  <p className='max-w-md text-grayishBlue'>Prosoape curate</p>
                </div>

                <div className='flex flex-col  items-center space-y-5'>
                  <FaParking
                    style={{
                      color: 'white',
                      fontSize: '60px',
                    }}
                  />
                  <p className='max-w-md text-grayishBlue'>Parcare Gratuita</p>
                </div>
              </div>
            </div>

            {/* ecran mic */}
            <div className='sm:hidden flex flex-col justify-between items-center'>
              <div className='flex flex-row items-center justify-between space-x-16 '>
                <FaWifi
                  style={{
                    color: 'white',
                    fontSize: '40',
                  }}
                />

                <FaUtensils
                  style={{
                    color: 'white',
                    fontSize: '40',
                  }}
                />

                <FaFan
                  style={{
                    color: 'white',
                    fontSize: '40',
                  }}
                />
              </div>
              {/* second container for 4 icons */}
              <div className='flex flex-row items-center justify-between space-x-16 mt-10'>
                <TbSpeedboat
                  style={{
                    color: 'white',
                    fontSize: '40',
                  }}
                />

                <GiBoatFishing
                  style={{
                    color: 'white',
                    fontSize: '40',
                  }}
                />

                <MdCleaningServices
                  style={{
                    color: 'white',
                    fontSize: '40',
                  }}
                />
              </div>

              {/* third shit */}
              <div className='flex flex-row items-center justify-between space-x-16 mt-10'>
                <FaTv
                  style={{
                    color: 'white',
                    fontSize: '40',
                  }}
                />

                <GiTowel
                  style={{
                    color: 'white',
                    fontSize: '40',
                  }}
                />

                <FaParking
                  style={{
                    color: 'white',
                    fontSize: '40',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Facilitati
