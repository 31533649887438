import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import logotr from '../../assets/logo_transparent.png'
import logotr2 from '../../assets/logo_transparent 2.png'

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const clickHandler = () => {
    setMenuOpen(!menuOpen)
  }
  return (
    <section className=''>
      {/* hero container */}
      <div className='container  mx-auto py-2 px-4   text-white'>
        {/* menu/logo container */}
        <nav className='flex justify-between items-center font-bold text-white'>
          {/* logo */}
          <Link to='/' className='block md:hidden'>
            <img src={logotr2} alt='' style={{ width: 150, height: 150 }} />
          </Link>
          <Link to='/' className='hidden md:block'>
            <img src={logotr} alt='' style={{ width: 200, height: 200 }} />
          </Link>

          {/* menu with hover effect underline */}
          <div className='hidden h-10 font-alata md:flex text-xl space-x-8'>
            <div className='group'>
              <Link to='/tarife' className='hover:text-gray-300'>
                Tarife
              </Link>
            </div>{' '}
            <div className='group'>
              <Link to='/excursii' className='hover:text-gray-300'>
                Excursii
              </Link>
            </div>{' '}
            <div className='group'>
              <Link to='/facilitati' className='hover:text-gray-300'>
                Facilități
              </Link>
            </div>{' '}
            <div className='group'>
              <Link to='/galerie' className='hover:text-gray-300'>
                Galerie
              </Link>
            </div>{' '}
          </div>
          {/* hamburger button */}
          <div className='md:hidden'>
            <button
              onClick={clickHandler}
              className={`${
                menuOpen ? 'open' : 'hamburger'
              } z-40 block hamburger md:hidden focus:outline-none`}
              id='#menu-btn'
              type='button'
            >
              <span className='hamburger-top'></span>
              <span className='hamburger-middle'></span>
              <span className='hamburger-bottom'></span>
            </button>
          </div>
        </nav>

        {/*  mobile menu */}
        {menuOpen ? (
          <div
            id='menu'
            className='absolute top-0 bottom-0 left-0 flex flex-col self-end  w-full min-h-screen py-1 pt-40 pl-12 space-y-3 text-lg text-white uppercase bgimage md:hidden'
          >
            <Link
              to='/tarife'
              className='hover:text-gray-400'
              onClick={toggleMenu}
            >
              Tarife
            </Link>
            <Link
              to='/excursii'
              className='hover:text-gray-400'
              onClick={toggleMenu}
            >
              Excursii
            </Link>
            <Link
              to='/facilitati'
              className='hover:text-gray-400'
              onClick={toggleMenu}
            >
              Facilități
            </Link>
            <Link
              to='/galerie'
              className='hover:text-gray-400'
              onClick={toggleMenu}
            >
              Galerie
            </Link>
          </div>
        ) : (
          <div
            id='menu'
            className='absolute top-0 bottom-0 left-0 hidden flex-col self-end  w-full min-h-screen py-1 pt-40 pl-12 space-y-3 text-lg text-white uppercase bgimage '
          >
            <Link to='/tarife' className='hover:text-gray-400'>
              Tarife
            </Link>
            <Link to='/excursii' className='hover:text-gray-400'>
              Excursii
            </Link>
            <Link to='/facilitati' className='hover:text-gray-400'>
              Facilitati
            </Link>
            <Link to='' className='hover:text-gray-400'>
              Support
            </Link>
          </div>
        )}
      </div>
    </section>
  )
}

export default Navbar
