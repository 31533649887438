import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'

function RootLayout() {
  return (
    <>
      <div className='bgimage min-h-screen '>
        <Navbar />

        <main className=''>
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  )
}

export default RootLayout
