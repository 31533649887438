function Button({ text, type }) {
  const handleClick = () => {
    if (type === 'email') {
      window.open('mailto:casamateimahmudia@gmail.com')
    } else if (type === 'call') {
      window.open('tel:+40748596553')
    }
  }
  return (
    <button
      onClick={handleClick}
      className='bg-white text-myBlue font-bold py-2 px-4 rounded-full hover:bg-myBlue hover:text-white'
    >
      {text}
    </button>
  )
}

export default Button
