import './App.css'
import { Helmet } from 'react-helmet'
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'
import RootLayout from './components/layout/RootLayout'
import Home from './pages/Home'
import Excursii from './pages/Excursii'
import Tarife from './pages/Tarife'
import Facilitati from './pages/Facilitati'
import Galerie from './pages/Galerie'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path='/excursii' element={<Excursii />} />
      <Route path='/tarife' element={<Tarife />} />
      <Route path='/facilitati' element={<Facilitati />} />
      <Route path='/galerie' element={<Galerie />} />
    </Route>
  )
)
function App() {
  // return <RouterProvider router={router} />
  return (
    <RouterProvider router={router}>
      <Helmet>
        <title>Casa Matei</title>
        <meta
          name='description'
          content='This is a description of my website.'
        />
        <meta
          name='keywords'
          content='Plimbari in Delta, Excursii cu barca, Pensiune Mahmudia, Cazare Delta Dunarii, Cazare Mahmudia, Cazare Delta, Delta Dunarii, Turism Mahmudia, Excursii Delta Dunarii, Pescuit Delta Dunarii'
        />
      </Helmet>
    </RouterProvider>
  )
}

export default App
