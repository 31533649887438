import React from 'react'
import casa from '../assets/casa.jpg'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <div className='container mx-auto max-w-6xl px-6 py-12'>
      <div className='flex flex-col items-center justify-center md:flex-row  '>
        <div className='flex flex-col items-center justify-between md:flex-row '>
          <div className='flex flex-col items-center space-y-4 max-w-md mt-8 mb-4 p-4 font-sans  text-gray-200 uppercase border-2 md:p-8 md:m-32 md:mx-0 animate-fade-in'>
            <h3 className='text-4xl font-bold text-center'>
              Vacanța perfectă în Delta Dunării
            </h3>
            <Link
              to='/facilitati'
              className='bg-white text-myBlue font-bold py-2 px-4 rounded-full hover:bg-gray-300'
            >
              Afla mai multe
            </Link>
          </div>
        </div>

        <div className=''>
          <img
            src={casa}
            alt=''
            style={{ width: 450, height: 600 }}
            className='border-2 border-gray-300'
          />
        </div>
      </div>
    </div>
  )
}

export default Home
