import { Link } from 'react-router-dom'
import logo from '../../assets/logo_transparent 2.png'
import fb from '../../assets/images/icon-facebook.svg'
import ig from '../../assets/images/icon-instagram.svg'
import yt from '../../assets/images/yt.svg'
import Button from '../Button'

function Footer() {
  return (
    <footer className='px-2 py-2 bg-gray-300'>
      <div className='container flex flex-col items-center justify-between mx-auto space-y-8 md:flex-row md:space-y-0 '>
        {/* logo */}
        <div className='flex flex-col items-center justify-between '>
          <Link to='/'>
            <img src={logo} alt='' className='ficon' />
          </Link>
          <p className='text-white '>Nr. de contact: 0748 596 553</p>
          <p className='text-white '>Adresa: Str. Tulcei Nr. 20, Mahmudia</p>
          <p className='text-white font-semibold '>
            Copyright &copy; 2023 Casa Matei{' '}
          </p>
        </div>

        {/* button container */}
        <div className='flex flex-col  space-y-8 md:space-x-8 md:flex-row md:space-y-0 '>
          <Button text='Trimite Email' type='email' />
          <Button text='Suna' type='call' />

          {/* doua butoane unu cu email, unu cu call */}
        </div>

        {/* socials */}
        <div className='flex flex-col space-x-6'>
          <a href='https://www.facebook.com/profile.php?id=100054443232765'>
            <img src={fb} alt='' className='ficon' />
          </a>
        </div>
        <div className='flex flex-col space-x-6'>
          <a href='https://www.instagram.com/casamateimahmudia/'>
            <img src={ig} alt='' className='ficon' />
          </a>
        </div>
        <div className='flex flex-col space-x-6'>
          <a href='https://www.youtube.com/watch?v=cx8Gs8zYXhY&ab_channel=RazvanCalota'>
            <img src={yt} alt='' className='ficon' />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
